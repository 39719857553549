import React from "react";
import { PageProps, Link, graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Gallery from "../components/gallery/gallery";
import Img from "gatsby-image";

const Home: React.FC<PageProps<void>> = ({ path, data }: any) => (
  <Layout path="/">
    <SEO title="Daitarou" />
    <h1>
      <ruby>
        こんにちは！<rt>Hello!</rt>
      </ruby>
    </h1>
    <h2>
      <ruby>
        広上大太郎だ。よろしく！
        <rt>My name is Daitarou Hirogami. Nice to meet you!</rt>
      </ruby>
    </h2>
    <section className="padding" id="section-about-me">
      <h2>About Me</h2>
      <p>Hi, there! My name's Daitarou. I'm a Japanese brown bear!</p>
      <p>Here's a little bit about myself:</p>
      <ul>
        <li>
          I'm kind. That's my favorite quality. I tend to be polite and happy,
          though I'm not afraid to let people know if I'm having a bad day and
          need help. I'm not immune from being sarcastic around my close
          friends.
        </li>
        <li>
          I'm smart, but I am also very forgetful. Sometimes I rush on my work
          and end up missing something important. I always have to triple-check
          to make sure I have everything I need.
        </li>
        <li>I'm bilingual in English and Japanese.</li>
        <li>
          I'm chubby and okay with that! It makes my bear hugs that much
          cuddlier. Please don't draw me thinner than portrayed in my ref.
        </li>
      </ul>
    </section>
    <section id="section-refsheet">
      <div className="padding">
        <h2>Refsheet</h2>
        <p>
          My awesome refsheet was made by <strong>servval</strong>!
        </p>
        <p>
          Check her out on&nbsp;
          <a href="https://furaffinity.net/user/servval">FA</a>&nbsp;or&nbsp;
          <a href="https://twitter.com/servvals">Twitter</a>!
        </p>
      </div>
      <a href={data.file.childImageSharp.original.src}>
        <Img fluid={data.file.childImageSharp.fluid}></Img>
      </a>
    </section>
    <section id="section-gallery">
      <div className="padding">
        <h2>Gallery</h2>
      </div>
      <Gallery></Gallery>
    </section>
    {/* <section>
      <div className="padding">
        <h2>Sticker Pack</h2>
        <p>
          I have a Telegram sticker pack with art from talented artists such as:
        </p>
        <ul></ul>
        <a href="https://telegram.me/addstickers/daitarou">Add to Telegram</a>
      </div>
    </section> */}
  </Layout>
);

export const query = graphql`
  query {
    file(relativePath: { eq: "ref/ref.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
        original {
          src
        }
      }
    }
  }
`;

export default Home;
