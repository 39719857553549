import { any } from "prop-types";

import React from "react";
import Img from "gatsby-image";
import "./gallery-image.css";

export class GalleryImageData {
  constructor(
    public generated: any,
    public original: string,
    public artist: string,
    public artistUrl: string,
    public year: string
  ) {}
}

export default ({ data }: { data: GalleryImageData }) => (
  <div>
    <div className="gallery-item-wrapper">
      <div className="gallery-image">
        <a href={data.original}>
          <div className="image-interaction">
            <Img fixed={data.generated}></Img>
          </div>
        </a>
      </div>
      <div className="caption">
        <div className="artist">
          <a href={data.artistUrl}>
            <strong>{data.artist}</strong>
          </a>
        </div>
        <div className="year">
          <small>{data.year}</small>
        </div>
      </div>
    </div>
  </div>
);
