import React from "react";
import GalleryImage from "../gallery-image/gallery-image";
import { graphql, useStaticQuery } from "gatsby";
import gallery from "../../gallery.json";
import { GalleryImageData } from "../gallery-image/gallery-image";
import "./gallery.css";

const query = graphql`
  query {
    allStrapiGalleryImage {
      nodes {
        year
        artist {
          name
          url
        }
        source {
          childImageSharp {
            thumb: fixed(height: 280) {
              ...GatsbyImageSharpFixed_withWebp
            }
            original {
              src
            }
          }
        }
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(query);

  const imgData: GalleryImageData[] = data.allStrapiGalleryImage.nodes.map(
    (node: any) => {
      return new GalleryImageData(
        node.source.childImageSharp.thumb,
        node.source.childImageSharp.original.src,
        node.artist.name,
        node.artist.url,
        node.year
      );
    }
  );

  return (
    <div className="gallery-carousel">
      {imgData.map((img, i) => (
        <GalleryImage data={img} key={i}></GalleryImage>
      ))}
    </div>
  );
};
